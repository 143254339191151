<template>
    <div class="pl-message-wrap">
        <div class="pl-unread-messages-box">
            <div class="pl-tab-box">
                <span :class="[param.status == 0 ? 'pl-unread-messages-title':'']" @click="tag(0)">未读消息</span>
                <span :class="[param.status == 1 ? 'pl-unread-messages-title':'']" @click="tag(1)">已读消息</span>
            </div>
            <div class="pl-parting-line"></div>
            <div class="pl-unread-messages">
                <div class="pl-unread-messages-content" v-for="(item, index) in tableData" :key="index"
                >
                    <div class="pl-messages-title pl-flex">
                        <div class="pl-flex-start">
                            <div class="pl-messages-type">{{item.title}}</div>
                            <div class="pl-message-time">{{item.created_at}}</div>
                        </div>
                        <div class="pl-flex-start">
                            <div v-if="item.status == 0" @click="messageStatus(item)"><img src="../shoppingCart/TF-icon/T.png"></div>
                            <div @click="delStatus(item)"><img src="../shoppingCart/TF-icon/F.png"></div>
                        </div>
                    </div>
                    <div class="pl-message-brief">{{item.content}}</div>
                </div>
            </div>
            <el-empty v-if="tableData.length == 0" description="暂无消息"></el-empty>
            <div class="pl-pagination pl-flex-start" v-if="tableData.length">
                <!-- <div class="pl-all-tags pl-flex-start pointer" @click="allRead" v-if="param.status == 0">
                    <img src="../shoppingCart/TF-icon/T.png">
                    <div>标记当前页为已读</div>
                </div> -->
                <div class="pl-pagination">
                <el-pagination
                background
                layout="prev, pager, next"
                :page-size="param.per_page"
                :total="param.total"
                @current-change="changePage">
                </el-pagination>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import {messageList,messageStatus,delMessage} from '@/api/message.js'
import {mapState} from 'vuex'
export default {
    data() {
        return {
            tableData: [],
            param: {
                page: 1,
                per_page: 20,
                status: 0,
                total: 0
            }
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        allRead() {

        },
        messageList() {
            messageList(this.param).then(({total, data}) => {
                this.param.total = total || 0
                this.tableData = data || []
            })
        },
        tag(type) {
            if(this.param.status == type) return false
            this.param.status = type
            this.messageList()
        },
        changePage(v) {
            this.param.page = v
            this.messageList()
        },
        messageStatus(res) {
            let {id, user_id} = res
            console.log(user_id, id, ":::del", res)
            messageStatus({user_id: user_id, ids: id+''}).then(() => {
                this.param.page = 1
                this.messageList()
            })
        },
        delStatus(res) {
            let {user_id, id} = res
            console.log(user_id, id, ":::del", res)
            delMessage({user_id: user_id, id: id}).then(() => {
                this.$message.success('已删除')
                this.param.page = 1
                this.messageList()
            })
        }
    },
    created() {
        this.messageList()
    }
}
</script>
<style lang="scss" scoped>
    img{
        width: 16px;
        margin-left: 20px;
        cursor: pointer;
    }
    .pl-unread-messages-box,.pl-all-news-box{
        width: 920px;
    }
    .pl-unread-messages-title{
        color: #D9262C;
    }
    .pl-all-news-title{
        color: #A2A2A2;
    }
    .pl-parting-line{
        width: 920px;
        height: 1px;
        background-color: #E9E9E9;
    }

    .pl-all-news{
        background-color: aqua;
    }
    .pl-unread-messages-content,.pl-all-news-content{
        width: 920px;
        background-color: #FFFFFF;
        border: 1px #EDEDEF solid;
        border-radius: 5px;
        margin: 10px 0px;
    }
    .pl-messages-title,.pl-message-brief{
        margin: 0px 20px;
    }
    .pl-messages-title{
        height: 30px;
        border-bottom: 1px #EDEDEF solid;
    }
    .pl-message-brief{
        height: 50px;
        line-height: 50px;
    }
    .pl-pagination{
        width: 920px;
    }
    .pl-all-tags {
        width: 200px;
    }
    .pl-all-tags img{
        margin: 0px 5px 0px 0px;
    }
    .pl-all-tags div{
        color: #3385FF;
    }
    .pl-tab-box {
        padding: 5px 0 15px;
        span {
            padding-right: 15px;
            cursor: pointer;
        }
    }
</style>
