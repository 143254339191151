import httpRequst from "../utils/request";
// 订单列表
export const messageList = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/message`, params)
}
// 已读
export const messageStatus = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/message/status
`, params)
}
// 删除
export const delMessage = function(params) {
    return httpRequst.delete(`${process.env.VUE_APP_BASEURL}/api/message/destroy
`, params)
}